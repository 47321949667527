import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import HomeLayout from "../components/HomeLayout/HomeLayout"
import Flippy, { FrontSide, BackSide } from "react-flippy"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const Trainings = () => {
  const [isFlipped1 , setIsFlipped1] = useState(false)
  const [isFlipped2 , setIsFlipped2] = useState(false)
  const [isFlipped3 , setIsFlipped3] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      PUC: allFile(filter: { sourceInstanceName: { eq: "PUC" } }) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Training: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Training" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      
    }
  `)
  return (
    <Layout>
      <HomeLayout>
        {data.Training.nodes.map(img => {
          var name = img.name.split(" ")
          console.log(name)
          if (
            name[2] === "Finearts" ||
            name[2] === "Workshops" ||
            name[2] === "IT"
          )
            return (
              <Link to={`/Training/${name[2]}`} key={img.id}>
                <Img fluid={img.childImageSharp.fluid} alt="Home images" />
              </Link>
            
          )
          else return null
        })}
        
        <div onClick={(e) => {
              if(!isFlipped1){
                  setIsFlipped2(false)
                  setIsFlipped3(false)
                  setIsFlipped1(true)
              }else{
                  setIsFlipped1(false)
              }
            setTimeout(() => setIsFlipped1(false), 3000)

          }} >
        <Flippy 
        flipDirection="horizontal"
        flipOnClick={false}
        isFlipped={isFlipped1}
        >
          <FrontSide>
            <Img
              fluid={data.Training.nodes[3].childImageSharp.fluid}
              alt="Front image"

            />
          </FrontSide>
          <BackSide>
            <Img fluid={data.PUC.nodes[0].childImageSharp.fluid} alt="Back image" fadeIn={false} />
          </BackSide>
        </Flippy>
        </div>
        <div onClick={() => {
              if(!isFlipped2){
                  setIsFlipped1(false)
                  setIsFlipped3(false)
                  setIsFlipped2(true)
              }else{
                  setIsFlipped2(false)
              }
            setTimeout(() => setIsFlipped2(false), 3000)

          }} >
        <Flippy 
        flipDirection="horizontal"
        isFlipped={isFlipped2 }

        >
          <FrontSide>
            <Img
              fluid={data.Training.nodes[4].childImageSharp.fluid}
              alt="Front image"
            />
          </FrontSide>
          <BackSide>
            <Img fluid={data.PUC.nodes[0].childImageSharp.fluid} alt="Back image" fadeIn={false} />
          </BackSide>
        </Flippy>
        </div>
        <div 
        onClick={() => {
          if(!isFlipped3){
              setIsFlipped1(false)
              setIsFlipped2(false)
              setIsFlipped3(true)
          }else{
              setIsFlipped3(false)
          }
          setTimeout(() => setIsFlipped3(false), 3000)

      }}
        >
        
        <Flippy flipDirection="horizontal" isFlipped={isFlipped3} >
          <FrontSide>
            <Img
              fluid={data.Training.nodes[5].childImageSharp.fluid}
              alt="Front image"
            />
          </FrontSide>
          <BackSide>
            <Img fluid={data.PUC.nodes[0].childImageSharp.fluid} alt="Back image" fadeIn={false} />
          </BackSide>
        </Flippy>
        </div>
      </HomeLayout>
      
    </Layout>
  )
}

export default Trainings
